import { useState, useEffect } from "react";
import "../styles/globals.css";
import Footer from "../components/Footer";
import { appWithTranslation, useTranslation } from "next-i18next";
import { GlobalStyle } from "../styles/global_styled_components";
import CookieConsent from "react-cookie-consent";
import Button from "../components/Button";
import Image from "next/image";
import Link from "next/link";
import cookie from "../public/images/cookie.png";
import Loader from "../components/loader/loader";
import Navigation from "../components/navigation/navigation";

function MyApp({ Component, pageProps }) {
   const [showContent, setShowContent] = useState(false)
   const [show, toggle] = useState(false)

   useEffect(() => {
      toggle(true)
      setTimeout(() => {
         setShowContent(300)
      }, 300)
      setTimeout(() => {
         toggle(false)
      }, 2500)
   }, [])

   return (
      <>
         <CookieConsent
            disableStyles={true}
            flipButtons
            enableDeclineButton
            buttonText={<Button text={"Akceptuję ciastka"} />}
            declineButtonText={<Button text={"Nie chcę ciastek!"} />}
            buttonWrapperClasses="buttons"
            contentClasses="text-center "
            overlayClasses="cookie-consent"
         >
            <Image src={cookie} alt="" />
            <p className="text-center">W celu wygodniejszego korzystania z naszego serwisu, korzystamy z plików cookie (ciastek).</p>
            <p className="text-center">Zapoznaj się z nasza {<Link href="/policies">Polityką Prywatności</Link>}, aby sprawdzić jakie stosujemy i do czego wykorzystujemy te informacje.</p>
         </CookieConsent>
         <Loader visible={show} />
         <GlobalStyle />
         {showContent && <Navigation />}
         <Component {...pageProps} />
         <Footer />
      </>
   );
}

export default appWithTranslation(MyApp);
