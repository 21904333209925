import React, { useEffect, useState } from "react";
import styled from "styled-components";
import polandIco from "../public/images/poland-icon.png";
import ukIco from "../public/images/united-kingdom-icon.png";
import GermanyIco from "../public/images/germany-icon.png";
import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import { DropdownOptionStyled, DropdownStyled, LangButtonStyled } from "./lang_switch/lang_switch.styles";

export default function LangSwitch({ setCurrentLoc, currentLoc }) {
   const { locale, asPath } = useRouter();
   const [currentFlag, setCurrentFlag] = useState(polandIco);
   const [opened, setOpened] = useState(false);

   useEffect(() => {
      if (locale === 'en') setCurrentFlag(ukIco)
      else setCurrentFlag(polandIco)
   }, [locale])

   return (
      <DropdownStyled>
         <LangButtonStyled open={opened} onClick={() => setOpened(!opened)}>
            <div className="flex items-center justify-start">
               <Image src={currentFlag} width={32} height={24} alt="eng_flag" />
            </div>
         </LangButtonStyled>
         <DropdownOptionStyled className={opened ? 'open' : ""}>
            <div className="py-3 px-3 flex flex-col">
               <Link href={asPath} locale="pl" className="w-full">
                  <div className="flex justify-start items-center w-full mb-2">
                     <Image src={polandIco} width={32} height={24} alt="pl flag" /> PL
                  </div>
               </Link>
               <Link href={asPath} locale="en">
                  <div className="flex items-center w-full">
                     <Image src={ukIco} width={32} height={24} alt="uk flag" /> EN
                  </div>
               </Link>
            </div>
         </DropdownOptionStyled>
      </DropdownStyled>
   );
}
