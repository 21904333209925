import { useEffect, useState } from "react"
import { HamburgerContainerStyled, HamburgerStyled } from "./hamburger.styles"

const Hamburger = ({ open, toggle }) => {

    return (
        <HamburgerContainerStyled onClick={() => toggle(!open)}>
            <HamburgerStyled className={open ? 'open' : ''}></HamburgerStyled>
        </HamburgerContainerStyled>
    )
}

export default Hamburger