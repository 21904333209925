import React, { useState } from "react";
import logo from "../../public/images/viramity-logo.png";
import LangSwitch from "../LangSwitch";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Hamburger from "../hamburger/hamburger";
import { AgencyBarStyled, LogotypeStyled, MenuLinks, NavbarListStyled, NavigationPanelStyled, OpenedMobileMenuStyled } from "./navigation.styles";
import Button from "../Button";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export default function Navigation({ setCurrentLoc, currentLoc }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation()

    const links = t('navigation.menu', { returnObjects: true })

    return (
        <NavigationPanelStyled>
            <div className="container mx-auto ">
                <div className="flex justify-between py-2 md:py-4">
                    <div className="flex">
                        <Link href="/">
                            <div className="logotype flex gap-2 items-center">
                                <Image src={logo} alt="Viramity" />
                                <LogotypeStyled>Viramity</LogotypeStyled>
                            </div>
                        </Link>
                    </div>
                    <NavbarListStyled className="flex flex-wrap justify-between lg:justify-end items-center gap-6">
                        <MenuLinks className="flex items-center gap-6 " open={isMenuOpen} onClick={() => setIsMenuOpen(false)}>
                            <ScrollLink href="#oferta" activeClass="active" className="" to="oferta" spy={true} smooth={true} duration={1500} onClick={() => setIsMenuOpen(false)}>
                                <li>{links[0]}</li>
                            </ScrollLink>
                            <ScrollLink href="#oferta" activeClass="active" className="" to="funkcje" spy={true} smooth={true} duration={1500} onClick={() => setIsMenuOpen(false)}>
                                <li>{links[1]}</li>
                            </ScrollLink>
                            <ScrollLink href="#oferta" activeClass="active" className="" to="dlaKogo" spy={true} smooth={true} duration={1500} onClick={() => setIsMenuOpen(false)}>
                                <li>{links[2]}</li>
                            </ScrollLink>
                            <ScrollLink href="#oferta" activeClass="active" className="" to="kontakt" spy={true} smooth={true} duration={1500} onClick={() => setIsMenuOpen(false)}>
                                <li>{links[3]}</li>
                            </ScrollLink>
                            <div className="block lg:hidden pl-4">
                                <Button text={"Demo"} />
                            </div>
                        </MenuLinks>
                        <div className="hidden lg:block">
                            <Button text={t('navigation.buttonContent')} />
                        </div>
                        <div className="flex items-center">
                            <LangSwitch setCurrentLoc={setCurrentLoc} currentLoc={currentLoc} />
                            <Hamburger open={isMenuOpen} toggle={setIsMenuOpen} />
                        </div>
                    </NavbarListStyled>
                </div>
            </div>
            <AgencyBarStyled>
                <div className="container mx-auto  text-right py-1">
                    <Link href="/agency">{t('navigation.agencyLink')}</Link>
                </div>
            </AgencyBarStyled>
            {/* {isMenuOpen && (
                <OpenedMobileMenuStyled>
                    <NavbarListStyled className="flex flex-col   gap-6">
                        <ul className="flex flex-col  gap-6 ">
                            <ScrollLink activeClass="active" className="" to="oferta" spy={true} smooth={true} duration={1500}>
                                <li>{links[0]}</li>
                            </ScrollLink>
                            <ScrollLink activeClass="active" className="" to="funkcje" spy={true} smooth={true} duration={1500}>
                                <li>{links[1]}</li>
                            </ScrollLink>
                            <ScrollLink activeClass="active" className="" to="dlaKogo" spy={true} smooth={true} duration={1500}>
                                <li>{links[2]}</li>
                            </ScrollLink>
                            <ScrollLink activeClass="active" className="" to="kontakt" spy={true} smooth={true} duration={1500}>
                                <li>{links[3]}</li>
                            </ScrollLink>
                        </ul>
                        <div className="block">
                            <Button text={"Demo"} />
                        </div>
                    </NavbarListStyled>
                </OpenedMobileMenuStyled>
            )} */}
        </NavigationPanelStyled>
    );
}
