import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

const zoom = keyframes`
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
`

export const FanContainerStyled = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.visible ? '#fff' : 'transparent'};
    z-index: 1000;
    pointer-events: none;
`

export const FanLogoStyled = styled.svg`
    animation: ${zoom} 1s infinite;
`

export const Frame1Styled = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 0;
    width: 120%;
    height: calc(110vh);
    background: #fdb400;
    z-index: 2;
`;
export const Frame2Styled = styled(Frame1Styled)`
    background: #a27ceb;
`;
export const Frame3Styled = styled(Frame1Styled)`
    background: #ff5670;
`;
export const Frame4Styled = styled(Frame1Styled)`
    background: #36d058;
`;

export const FanLogotypeStyled = styled.h2`
   color: #ff5670;
   font-weight: 700;
   font-size: 24px;
   text-transform: uppercase;
   margin-top: 15px;
`;