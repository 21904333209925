import styled from "styled-components";

export const NavigationPanelStyled = styled.div`
  position: fixed;
  z-index: 99;
  width: 100vw;
  background-color: white;
  .logotype {
    cursor: pointer;
  }
  .logo-mobile {
    width: 45px;
  }
`;

export const MenuLinks = styled.ul`
    position: relative;

    @media (max-width: 1024px){
        position: fixed;
        transition: all .3s;
        top: 96px;
        left: ${(props) => props.open ? '0' : '-100vw'};
        width: 85vw;
        height: 100vh;
        background-color: #fff;
        z-index: 100;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0 .5rem .6rem rgba(0,0,0,.26);
        padding-top: 2rem;
        pointer-events: none;

        & li{
            font-size: 30px;
            pointer-events: initial;
        }

        &::before{
            content: "";
            pointer-events: initial;
            position: absolute;
            left: 85vw;
            top: 0;
            width:15vw;
            height: 100%;
            z-index: -2;
        }
    }

    @media (max-width: 767px){
        top: 80px;
    }
`

export const OpenedMobileMenuStyled = styled.div`
  position: absolute;
  top: 127px;
  background-color: #f5f8fd;
  padding: 24px;
  width: 100vw;
  height: 100vh;
  li {
    margin: 0;
    font-size: 24px;
  }
`;

export const LogotypeStyled = styled.h2`
  color: #ff5670;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const AgencyBarStyled = styled.h2`
  background-color: #1c174c;
  color: white;
  text-decoration-line: underline;
  font-weight: 300;
  font-size: 16px;
`;

export const NavbarListStyled = styled.div`
  li {
    list-style: none;
    cursor: pointer;
  }
`;
