import styled from "styled-components";

export const DropdownStyled = styled.div`
    position: relative;
    z-index: 9999;
    background: #1c174c;
    border-radius: 8px;
    cursor: pointer;

    .closed {
        display: none;
    }
`;

export const LangButtonStyled = styled.button`
    padding: 12px 44px 12px 19px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    cursor: pointer;

    @media (max-width: 1024px){
        padding: 8px 34px 8px 10px;
    }

    &::before {
        content: url(/images/icons/arr_down.png);
        position: absolute;
        right: 10px;
        top: ${(props) => props.open ? "10px" : '14px'};
        z-index: 999;
        transform-origin: center;
        transition: all .3s;
        transform: ${(props) => props.open ? "rotate(-180deg)" : 'rotate(0deg)'};

        @media (max-width: 1024px){
        top: ${(props) => props.open ? "6px" : '10px'};
            right: 5px;
        }
    }
`;

export const DropdownOptionStyled = styled.div`
    position: absolute;
    top: 52px;
    width: 100%;
    background-color: #f5f8fd;
    overflow: hidden;
    box-shadow: 0 0 0 1px #fff;
    border-radius: 8px;
    transition: all .3s;
    overflow: hidden;
    max-height: 0;

    &.open{
        max-height: 150px;
        box-shadow: 0 0 0 1px #1c174c;
    }

    div {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }
`;