import styled from "styled-components";

export const HamburgerContainerStyled = styled.div`
    display: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    @media (max-width: 1024px){
        display: flex;
    }
`

export const HamburgerStyled = styled.div`
    &, &::before, &::after{
        display: block;
        background-color: #000;
        position: absolute;
        height: 4px;
        width: 40px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;
    }

    &::before{
        content: '';
        margin-top: -9px;
    }

    &::after{
        content: '';
        margin-top: 9px;
    }

    &.open {
        background-color: #fff;

        &::before{
            margin-top: 0px;
            transform: rotate(405deg);
        }
        &::after{
            margin-top: 0px;
            transform: rotate(-405deg);
        }
    } 
`