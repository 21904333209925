import React from "react";
import styled, { keyframes } from "styled-components";
import { CgSpinnerTwoAlt } from 'react-icons/cg'

export default function Button({ text, type = "button", fullWidth = false, onClick = null, disabled = false }) {
   return (
      <Btn type={type} fullWidth={fullWidth} onClick={onClick} disabled={disabled}>
         {disabled ? <CgSpinnerTwoAlt className="animate-spin text-2xl"/> : text}
      </Btn>
   );
}
const Btn = styled.button`
   background-color: #1c174c;
   transition: all 0.5s;
   border-radius: 8px;
   padding: 12px 40px;
   cursor: pointer;
   color: white;
   font-weight: 600;
   font-size: 16px;
   width: ${(props) => (props.fullWidth ? "100%" : "auto")};
   position: relative;
   z-index: 2;
   display: flex;
   justify-content: center;

   &:disabled{
      background-color: #6d6c7a;
   }

   @media (max-width: 1024px) {
      width: 100%;
   }
   :hover {
      transition: all 0.5s;
      background-color: white;
      cursor: pointer;
      color: #1c174c;
      font-weight: 600;
      font-size: 16px;
      outline: 1px solid #1c174c;
      outline-offset: -1px;

      &:disabled{
         background-color: #6d6c7a;
         color: white;
         outline: none;
      }
   }
`;
