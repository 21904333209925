import Image from 'next/image'
import { motion } from "framer-motion";
import { sliderContainer, slider, pageAnimation, fade, photoAnim, lineAnim, slider_bg } from "../animations";
import { FanContainerStyled, FanLogoStyled, FanLogotypeStyled, Frame1Styled, Frame2Styled, Frame3Styled, Frame4Styled } from "./loader.styles";

const Loader = ({ visible }) => {
    return (
        <FanContainerStyled visible={visible}>
            {/* <div className="relative w-full h-full flex flex-col justify-center items-center"> */}

            <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                <motion.div variants={sliderContainer}>
                    <motion.div className='w-full h-full fixed left-0 top-0 bg-white flex flex-col justify-center items-center' variants={slider_bg}>
                        {visible && <FanLogoStyled width="122" height="130" viewBox="0 0 112 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M111.153 72.1977C111.146 75.1343 108.419 76.9523 105.895 76.9523C104.976 76.9523 104.095 76.7346 103.276 76.3045L60.4105 53.8228C58.799 52.9776 57.7953 51.4044 57.7906 49.7169L57.6875 4.83143C57.6846 3.55621 58.2234 2.36369 59.2017 1.47546C60.1928 0.574773 61.5582 0.0583502 62.945 0.0583502C63.8592 0.0583502 64.7383 0.274259 65.5552 0.701696L108.619 23.1792C110.246 24.027 111.252 25.6097 111.247 27.309L111.153 72.1977Z" fill="#FF5670" />
                            <path d="M50.9344 53.7743L7.8677 76.25C7.05078 76.6774 6.17257 76.8933 5.2575 76.8933C3.87067 76.8933 2.50714 76.3769 1.51424 75.4762C0.535869 74.587 -0.00292589 73.3954 1.19511e-05 72.1202L0.103101 27.2348C0.106991 25.5471 1.11065 23.974 2.72301 23.1289L45.5881 0.647812C46.4079 0.218631 47.2891 5.98919e-05 48.208 5.98919e-05C50.7318 5.98919e-05 53.4588 1.81817 53.4655 4.75459L53.5627 49.6445C53.5666 51.3437 52.5591 52.9256 50.9339 53.7753L50.9344 53.7743Z" fill="#FF5670" />
                            <path d="M37.956 119.413C36.8904 118.68 36.3537 117.413 36.5216 116.029L38.1196 102.841L9.95305 88.1256C8.31038 87.2664 7.32715 85.7285 7.32617 84.0091C7.32518 82.2906 8.30542 80.7491 9.94608 79.8889L52.7995 57.4177C53.597 57 54.5054 56.7788 55.4273 56.7788C56.3463 56.7788 57.2507 56.9974 58.0472 57.4133L101.129 79.8987C102.774 80.7581 103.758 82.2967 103.759 84.0161C103.761 85.7355 102.78 87.277 101.138 88.1371L74.6481 102.015C74.433 102.19 74.2017 102.344 73.9579 102.473L41.6299 119.544C40.3955 120.195 39.0221 120.147 37.956 119.413Z" fill="#FF5670" />
                        </FanLogoStyled>}
                        {visible && <FanLogotypeStyled>Viramity</FanLogotypeStyled>}
                    </motion.div>
                    <Frame1Styled variants={slider}></Frame1Styled>
                    <Frame2Styled variants={slider}></Frame2Styled>
                    <Frame3Styled variants={slider}></Frame3Styled>
                    <Frame4Styled variants={slider}></Frame4Styled>
                </motion.div>
            </motion.div>
            {/* </div> */}
        </FanContainerStyled>
    )
}

export default Loader